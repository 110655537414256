import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  doCreateCartAction,
} from '../../redux/actions/products';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import { routers} from '../../config/routers';
import { getTranslatedText } from '../../services/appServices';
import {
  usdCurrencyFormat,
  vndCurrencyFormat,
} from '../../services/appServices';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const ProductCardComponent = (props) => {

  const {
    id,
    short_name,
    unit,
    category_id,
    avatar,
    name,
    price,
    market_price,
    unit_price,
    actions,
    accountType,
  } = props;

  const history = useHistory();

  const buyButtonClickHandle = () => {
    if (accountType === 'partner-product' || accountType === 'partner-service') {
      toast.warn('Vui lòng đăng kí tài khoản khách hàng để thực hiện thao tác này');
    } else {
      const cartPayload = {
        cartItem: {
          product_name: name,
          product_id: id,
          price: price,
          product_unit: unit,
          quantity: 1,
          user_create: short_name,
          product_price_unit: unit_price,
          provider_short_name: short_name,
          product_avatar: avatar
        },
        orderType: 'le'
      }
      actions.doCreateCartAction(cartPayload);
      history.push(routers.order.cart_retail);
    }
  }

  return (
    <React.Fragment>
      <div className="item_product">
        <Link to={
          routers.products.detail_product.replace(':short_name', short_name).replace(':category_id', category_id).replace(':product_id', id)
        }
        >
          {avatar === "" ? (
            <img src={require("../../assets/product/wireframe.png")} alt="" />
          ) : (
              <img style={{ height: '263px' }} src={avatar} alt="" />
            )}
        </Link>
        <div className="_content">
          <div className="_title">{name.toUpperCase()}</div>
          <div style={{
            height: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            {unit_price === 1 ? (
              <div className='_price-wrap'>
                <div className="_price">{vndCurrencyFormat.format(price)}</div>
                <div className="_old">{vndCurrencyFormat.format(market_price)}</div>
              </div>
            ) : (
              <div className='_price-wrap'>
                <div className="_price">{usdCurrencyFormat.format(price)}</div>
                <div className="_old">{usdCurrencyFormat.format(market_price)}</div>
              </div>
            )}
            {/*<button*/}
            {/*  style={{*/}
            {/*    width: '73px',*/}
            {/*    height: '26px',*/}
            {/*    fontSize: '9px',*/}
            {/*    textAlign: 'center',*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center'*/}
            {/*    }}*/}
            {/*  className="btn small add_cart"*/}
            {/*  onClick={() => buyButtonClickHandle()}*/}
            {/*>*/}
            {/*  {getTranslatedText('btn_buy')}*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProductCardComponent.propType = {
  id: PropTypes.number,
  unit: PropTypes.string,
  unit_price: PropTypes.number,
  short_name: PropTypes.string,
  category_id: PropTypes.number,
  avatar: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  market_price: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    accountType: state.getUserFromCookiesReducer.accountType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      doCreateCartAction,
    },
    dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCardComponent);